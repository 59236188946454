@import 'src/styles/_variables';

.title {
  color: $dark-gray;
  font-weight: $font-weight-extra-bold;
  font-size: 20px;
  line-height: 28px;
}

.subtitle {
  color: $dark-gray;
  font-weight: $font-weight-regular;
  font-size: 14px;
  line-height: 22px;
}


.item {
  position: relative;
  width: 100%;
  max-height: 200px;
  margin-bottom: 8px;
  padding: 16px;
  overflow: hidden;
  background: $white;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: max-height .5s, padding .5s;
  transition: all 0.3s ease-in;
  user-select: none;
}

.itemHeader {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.itemImg {
  width: 24px;
  height: 24px;
  margin-right: 11px;
  object-fit: fill;
}

.service {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.service a{
  width: 120px;
}
.service button{
  text-align: center;
}
.service .flex1{
  width: 100%;
}
.flex1 button{
  width: 100%;
}
.link {
  padding-top: 16px;
  color: $light-blue;
  font-size: 14px;
  line-height: 22px;
}

.item::before {
  position: absolute;
  top: 24px;
  left: 90%;
  display: block;
  width: 7px;
  height: 7px;
  border-bottom: 3px solid $light-blue;
  border-left: 3px solid $light-blue;
  transform: rotate(225deg);
  transition: transform ease-in 0.3s;
  content: '';
}

.activeItem {
  max-height: 700px;
  background: $light-blue-background;
  transition: all ease-in 0.3s;
}

.activeItem::before {
  transform: rotate(315deg);
  transition: transform ease-in 0.3s;
}

.toggleBox {
  min-width: 120px;
  margin: 16px 0;
}

.label {
  color: $light-black;
  font-weight: $font-weight-extra-bold;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
}