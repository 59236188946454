@import 'src/styles/_variables';

.button {
  width: 100%;
  position: relative;
  padding: 3px 24px;
  font-weight: $font-weight-extra-bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  border: none;
  border-radius: $border-radius;
  outline: none;
  cursor: pointer;
}

.containedOrange {
  color: white;
  background: $dark-orange;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
}

.containedWhite {
  color: $dark-gray;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
}

.outline {
  box-sizing: border-box;
  background: $light-blue-background;
  border: 1px solid #3F444Ea1;
}

.arrow {
  padding-right: 40px;
}

.arrow::before {
  position: absolute;
  top: 45%;
  left: 85%;
  display: block;
  width: 7px;
  height: 7px;
  border-bottom: 3px solid;
  border-left: 3px solid;
  transform: rotate(225deg);
  content: '';
}

.titleCenter {
  text-align: center;
}