@import 'src/styles/_variables';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

.iframeCollaboration {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 5px;
}

.mobile {
  min-width: 375px;
}

.table {
  min-width: 768px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  margin: auto;
  transform: translate(-50%, -50%);
}

.sectionCollaboration {
  box-sizing: border-box;
  width: 100%;
  background: $background-light;
  border: 1px solid $subhead-gray;
}

.control {
  padding: 12px 16px;
}

.label {
  color: $dark-gray;
  font-weight: $font-weight-extra-bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.close {
  position: absolute;
  top: 0;
  right: 12px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
}

.close::before, .close::after {
  position: absolute;
  left: 15px;
  width: 2px;
  height: 14px;
  background-color: #333;
  content: ' ';
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}

.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  margin-right: 8px;
  padding: 12px 16px;
  background: #FFF;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  outline: none;
  &::placeholder {
    color: $dark-gray-navigation;
    font-size: 14px;
    line-height: 22px;
  }
}

.sectionError {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.box {
  width: 227px;
  height: 160px;
}

.boxImg {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.placeholder {
  color: $light-gray-navigation;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
