@import 'src/styles/_variables';

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 10px;

  @media screen and (max-width: 767px) {
    min-width: 40px;
    margin: 0;
  }
  .button{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 6px 16px 6px 6px;
    color: $white;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    &::after {
      position: absolute;
      top: 19px;
      right: 4px;
      display: block;
      width: 7px;
      height: 7px;
      border-color: $white;
      border-bottom: 3px solid;
      border-left: 3px solid;
      transform: rotate(135deg);
      transition: transform ease-in 0.3s;
      content: '';
    }
    &.isMobile{
      color: black;
    }
  }
  &:hover{
    .button::after{
      transform: rotate(315deg);
    }
    .languageList{
      display: block;
    }
  }
  .languageList{
    display: none;
    position: absolute;
    top: 35px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 120px;
    padding: 8px 12px;
    background: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
    @media screen and (max-width: 991px) {
      width: 131px;
    }
    .item {
      display: block;
      width: 100%;
      text-align: center;
      padding: 6px 0px;
      color: $dark-gray;
      background: inherit;
      border: none;
      outline: none;
      font-size: 14px;
      cursor: pointer;
    }
    
    .itemActive {
      font-weight: $font-weight-extra-bold;
    }
  }
}
