.playgroundContainer{
  max-height: 100vh;
  overflow: hidden;
}

.header{
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
}

.title{
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
}

.actions{
  display: flex;
  justify-content: space-between;
}

.aiButton{
  display: inline-flex;
  font-size: 16px;
  font-weight: 700;
  color: #923094;
  text-decoration: underline;
  border-right: 1px solid #C5C5C5;
  padding-right: 10px;
  margin-right: 5px;
  white-space: nowrap;
  > img{
    margin-right: 5px;
  }
}
