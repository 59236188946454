.script-lab {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #923094;
  }

  .ant-tabs .ant-tabs-tab {
    padding: 8px 0;

    &:hover {
      color: #923094;
    }
  }

  .ant-tabs .ant-tabs-ink-bar {
    background: #923094;
  }

  .ant-tabs {
    padding-left: 6px;
  }

  .actions {
    margin-right: 10px;
    .ant-btn {
      border-radius: 0;
      font-size: 14px;
      padding: 2px 8px;
      height: 28px;

      &.ant-btn-primary {
        background: #923094;
      }
    }
  }
}