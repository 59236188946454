@import 'src/styles/_variables';
 
.container {
  position: relative;
  box-sizing: border-box;
  width: 90%;
  margin: 0 24px;
  padding: 12px 16px 12px 12px;
  color: $white;
  background: $dark-gray-navigation;
  border: 1px solid $dark-gray-navigation;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
}

.container::after {
  position: absolute;
  top: 45%;
  right: 12px;
  display: block;
  width: 7px;
  height: 7px;
  border-bottom: 3px solid;
  border-left: 3px solid;
  transform: rotate(135deg);
  transition: transform ease-in 0.3s;
  content: '';
  @media screen and (max-width: 576px) {
    left: 90%;
  }
}

.title {
  font-size: 14px;
  line-height: 20px;
}

.containerActive {
  color: $subhead-gray;
  background: $light-black;
  border: 1px solid $light-black;
}

.containerActive::after {
  transform: rotate(315deg);
  transition: transform ease-in 0.3s;
}