@import 'src/styles/_variables';

.container {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  min-width: 342px;
  max-width: 342px;
  background: #F0F0F0;
  border: 1px solid #DBDBDB;
  overflow: auto;
}

.containerMobile {
  @extend .container;
  display: none;
  position: absolute;
  min-width: 100%;
}
.containerTablet {
  @extend .container;
  display: none;
  position: absolute;
}

.info {
  margin: 8px;
  border-radius: 4px;
}

.title {
  color: $dark-gray;
  font-weight: $font-weight-extra-bold;
  font-size: 20px;
  line-height: 28px;
}

.subtitle {
  color: $dark-gray;
  font-weight: $font-weight-regular;
  font-size: 14px;
  line-height: 22px;
}

.subtitle:last-child {
  margin-top: 14px;
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px;
}

.item {
  width: 100%;
  margin-bottom: 8px;
  padding: 16px;
  background: $white;
  border-radius: 4px;
}
.btnLink {
  display: block;
  width: 100%;
}
.itemHeader {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.itemImg {
  width: 24px;
  height: 24px;
  margin-right: 11px;
  object-fit: fill;
}

.btn {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 12px;
}

.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba($light-black, 0.5);
}

.isOpen {
  display: flex;
  overflow: scroll;
}