@import 'src/styles/_variables';


.section {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-width: 131px;
  height: 52px;
  margin-right: 24px;

  @media screen and (min-width: 991px) {
    margin-left: 24px;
  }

  @media screen and (max-width: 767px) {
    min-width: 40px;
    margin: 0;
  }
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px 16px 12px 12px;
  color: $white;
  background: $dark-gray-navigation;
  border: 1px solid $dark-gray-navigation;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
}

.container::after {
  position: absolute;
  top: 24px;
  right: 12px;
  display: block;
  width: 7px;
  height: 7px;
  border-bottom: 3px solid;
  border-left: 3px solid;
  transform: rotate(135deg);
  transition: transform ease-in 0.3s;
  content: '';
}

.imgBox {
  width: 24px;
  margin-right: 8px;
  cursor: pointer;
}
.iconSelect {
  width: 24px;
  height: 24px;
}
.placeholder {
  color: $subhead-gray;
  font-size: 14px;
  line-height: 20px;
}

.item {
  margin-top: 24px;
  color: $dark-gray;
  background: inherit;
  border: none;
  outline: none;
  cursor: pointer;
}

.itemActive {
  font-weight: $font-weight-extra-bold;
}

.boxOpen {
  position: absolute;
  top: 60px;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 24px;
  background: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  @media screen and (max-width: 991px) {
    width: 131px;
  }
}

.title {
  color: $light-gray-navigation;
  font-weight: $font-weight-extra-bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  user-select: none;
}

.containerActive {
  color: $subhead-gray;
  background: $light-black;
  border: 1px solid $light-black;
}

.containerActive::after {
  transform: rotate(315deg);
  transition: transform ease-in 0.3s;
}

.visible {
  display: none;
}