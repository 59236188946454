@import 'src/styles/_variables';

.header {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 0 24px;
  background: $dark-gray;
}

.navigation {
  display: flex;
  align-items: center;
}

.item {
  min-width: 110px;
  margin: 0 24px;
  color: $light-gray-navigation;
  font-weight: $font-weight-extra-bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.label {
  color: $white;
  font-weight: $font-weight-extra-bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-align: right;
  text-transform: uppercase;
}

.service {
  display: flex;
  align-items: center;
}

.logo {
  cursor: pointer;
  width: 64px;
  height: 26px;
}

.visible {
  display: none;
}

.blockImg {
  min-width: 64px;
  @media screen and (max-width: 767px) {
    min-width: 48px;
  }
}

.img {
  width: 100%;
  height: 100%;
}

.btn {
  width: max-content;
}

.toggleBox {
  min-width: 120px;
}

.menu {
  min-width: 30px;
  background: $dark-gray;
  border: none;
  outline: none;
  cursor: pointer;
}

.burgerContainer {
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;
  z-index: 7;
  padding: 24px 32px;
  background: $white;
}

.burgerList {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.burgerItem {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin-bottom: 20px;
}

.quote {
  color: $dark-gray;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.quote::after {
  position: absolute;
  top: 40%;
  left: 55%;
  display: block;
  width: 7px;
  height: 7px;
  border-bottom: 3px solid;
  border-left: 3px solid;
  transform: rotate(225deg);
  transition: transform ease-in 0.3s;
  content: '';
}

.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  background-color: rgba($light-black, 0.5);
}

.aiLinkBtn {
  border: 2px solid $dark-orange;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.28));
  border-radius: 10px;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  color: $dark-orange;
  text-align: center;
  padding: 2px 16px;
}