// Colors
$dark-gray: #3F444E;
$light-gray-navigation: #9FA4AD;
$dark-gray-navigation: #595C64;
$light-black: #272D38;
$light-blue:#0A7DDF;
$light-blue-background: #C0EAFB;
$subhead-gray: #DBDBDB;
$dark-orange: #F2962E;
$white: #FFFFFF;
$background-hero:#272D38;
$background-light: #F0F0F0;


// Fonts
$font-weight-small: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// Border
$border-radius: 10px;