.floatButton{
  background: #F0952E;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.28);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  user-select: none;
  img{
    user-select: none;
  }
}
