.runner{
  padding: 5px 8px;
  .actions {
    margin-bottom: 8px;
    .ant-btn {
      border-radius: 0;
      font-size: 14px;
      padding: 2px 8px;
      height: 28px;
  
      &.ant-btn-primary {
        background: #923094;
      }
    }
  }
}

.runner-content{
  min-height: calc(100vh - 80px);
}