.main {
  display: flex;
  height: 100%;
  padding-top: 80px;
  max-height: 100vh;
}

.content {
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 342px;
  display: flex;
  @media screen and (max-width: 900px) {
    left: 0;
  }
}

.iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

.iframeOverlay{
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.floatButton{
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
}
