.splitter-layout {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.splitter-layout .layout-pane {
  position: relative;
  flex: 0 0 auto;
  overflow: hidden;
}

.splitter-layout .layout-pane.layout-pane-primary {
  flex: 1 1 auto;
}

.splitter-layout > .layout-splitter {
  flex: 0 0 auto;
  height: 100%;
  cursor: col-resize;
  background: #000;
  opacity: 0.2;
  z-index: 9999;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
}

.splitter-layout .layout-splitter:hover {
  transition: all 2s ease;
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.splitter-layout.layout-changing {
  cursor: col-resize;
}

.splitter-layout.layout-changing > .layout-splitter {
  background-color: #aaa;
}

.splitter-layout.splitter-layout-vertical {
  flex-direction: column;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
  cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
  width: 100%;
  height: 4px;
  cursor: row-resize;
}